<template>
    <mercur-card class="reset-password-form">
        <h2 class="font-weight-normal">Set your new password</h2>

        <form @submit.prevent="submitForm" autocomplete="off">
            <mercur-input
                v-model="form.username"
                id="username"
                type="text"
                :class="{'form-invalid': $v.form.username.$error}">
                E-mail
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.username.required">Username is required</span>
                </template>
            </mercur-input>

            <mercur-input
                v-model="form.newPassword"
                id="newPassword"
                type="password"
                :class="{'form-invalid': $v.form.newPassword.$error}">
                Password
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.newPassword.required">Password is required</span>
                    <span class="form-error" v-if="!$v.form.newPassword.minLength">Password needs to have at least 8 characters</span>
                    <span class="form-error" v-if="!$v.form.newPassword.isValidPassword">Password needs to contain at least one uppercase letter, one number and one special character</span>
                </template>
            </mercur-input>

            <mercur-input
                v-model="form.passwordRepeat"
                id="passwordAgain"
                type="password"
                :class="{'form-invalid': $v.form.passwordRepeat.$error}">
                Password
                <template slot="note">
                    <span class="form-error" v-if="!$v.form.passwordRepeat.required">Password again is required</span>
                    <span class="form-error" v-else-if="!$v.form.passwordRepeat.sameAsPassword">Passwords are not matching</span>
                </template>
            </mercur-input>

            <div class="text-right">
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" type="submit" :disabled="loading">Reset Password</mercur-button>
            </div>
            <br>
        </form>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
const isValidPassword = (value) => {
    return /[a-z]/.test(value) && /[A-Z]/.test(value) && /[0-9]/.test(value) && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)
}

export default {
    name: 'PasswordRecoverView',
    data () {
        return {
            form: {},
            action: CONFIG.API.ROUTES.ACCOUNT.RESET_PASSWORD,
            loading: false,
        }
    },
    validations: {
        form: {
            username: {
                required,
            },
            newPassword: {
                required,
                minLength: minLength(8),
                isValidPassword,
            },
            passwordRepeat: {
                required,
                sameAsPassword: sameAs('newPassword'),
            },
        },
    },
    methods: {
        submitForm () {
            this.$v.$touch()

            if (this.$v.$invalid) {
                return
            }

            this.form.recoveryToken = this.$route.params.recoveryToken
            this.loading = true
            this.addJob(this.action)
            this.$api.post(this.action, this.form).then(data => {
                this.$root.$emit('notification:global', {
                    message: 'Password was reset',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'LoginView',
                    })
                }, 1000)
            }).finally(() => {
                this.finishJob(this.action)
                this.loading = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .reset-password-form {
        margin: auto;
        width: 100%;
        max-width: 500px;
    }
</style>
